import React from 'react';
import logo from './logo.svg';
import './Splash.css';

function Splash() {
  return (
    <div className="Splash">
      <header className="Splash-header">
        <img src={logo} className="Splash-logo" alt="logo" />
        {/* Draft splash text version A:
        <p>
          Deep Saturn offers an unparalleled personalized consulting experience for you. We may be
          leveraging AI or extensive data insights. The consulting experience, however, will feel
          like a tailored consultant right next to you. We will also be accompanying you and your
          business along various stages of your successes and assist when needed. Reach out to an
          agent today and see how we can help.
        </p>
        */}
        <p>
          Deep Saturn site is under construction. We are accepting new clients!
        </p>
        <a
          className="Splash-link"
          href="mailto:info@deepsaturn.com?subject=Business%20Inquiry%3A"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </header>
    </div>
  );
}

export default Splash;
